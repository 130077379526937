.component,
.tripRoute  {
  height: 100%;
  width: 100%;
  display: -ms-grid;
  display: grid;
       -ms-grid-rows: 1fr clamp(155px, 30vh, 100%);
  grid-template-rows: 1fr clamp(155px, 30vh, 100%);
  position: relative;
  overflow: hidden;
}

.tripFocus {
  -ms-grid-rows: 0 100%;
  grid-template-rows: 0 100%;
}

#tripSummary {
  display: none;
}

.component summary {
  height:100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
  grid-template-rows: 1fr 4.5fr 3.5fr 3.5fr;
  -ms-flex-line-pack: start;
      align-content: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 1vh;
  padding: 1vh 1vh 0 1vh;
  overflow: hidden;
}

.component #tripSummary {
  -ms-grid-rows: none;
  grid-template-rows: none;
  padding: 0 1.18vh 0 1.18vh;
}

.statContainer {
  display: -ms-grid;
  display: grid;
  grid-gap: .95vh;
}

.component summary .statsHeader,
.component summary .cardStat {
  display: -ms-grid;
  display: grid;
  place-items: center;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  padding: 00.60vh;
}

.component summary .statsHeader {
  grid-column: 1 / -1;
}

.component summary .cardStat {
  padding: 0;
  width: 100%;
  -ms-grid-column-align: center;
      justify-self: center;
}

.component summary .cardStat > * {
  padding: 00.59vh;
}

.component section {
  height: 100%;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.component section::-webkit-scrollbar {
  display: none;
}
.component .stepCard {
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  place-self: center;
  width: 92%;
  height: 85%;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  display: -ms-grid;
  display: grid;
  grid-gap: 15px;
  place-items: center;
}

.component .stepCard form {
  display: -ms-grid;
  display: grid;
  grid-gap: 15px;
  width: 80%;
}

.component .stepCard input {
  all: unset;
  display: block;
  cursor: text;
  border-radius: 10px;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  padding: 5px 0;
  text-align: center;
}

.component .stepCard label {
  position: relative;
  display: block;
}

.component .stepCard .clientList {
  width: 100%;
}

.component .stepCard .clientList button,
.component .stepCard .clientList button:disabled {
  width: 80%;
  justify-self: center;
}

.component .stepCard .clientList > div{
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 10px;
}

.component .stepCard .clientList label {
  border-radius: 5px;
  width: 12.5vw;
  height: 12.5vw;
  overflow: hidden;
  cursor: pointer;
}

.component .stepCard input[type=radio] {
  display: none;
}

.component .stepCard input:checked + label {
  border: 2px solid dimgrey;
  color: black;
  -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0, .5);
          box-shadow: 0 0 2px 0 rgba(0,0,0, .5);
}

.component .stepCard label img {
  width: 100%;
  height: 100%;
}

.component .stepCard button,
.component .stepCard button:disabled {
  all: unset;
  text-decoration: none;
  background-color: green;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
}

.component .stepCard button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

#actionButtons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

#actionButtons h4 {
  -ms-grid-column-span: 2;
  grid-column: span 2;
}
 
#pause,
#end {
  -ms-grid-column-align: end;
      justify-self: end;
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
  position: relative;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(255,165,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(255,165,0, .25);
  border-radius: 10px;
  width: 78%;
}

#end {
  -ms-grid-column-align: start;
      justify-self: start;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(255,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(255,0,0, .25);
}

#pause button,
#end button {
  background-color: white;
  border: 1px solid rgba(255,165,0, .3);
  color: black;
  padding: 7px 0;
}

#end button {
  border: 1px solid rgba(255,0,0, .3);
}

#start{
  -ms-grid-column-span: 2;
  grid-column: span 2;
  -ms-grid-row-align: start;
      align-self: start;
  position: relative;
}

#headOut {
  -ms-grid-column-span: 2;
  grid-column: span 2;
  -ms-grid-row-align: start;
      align-self: start;
}

#actionButtons form .buttonIcon {
  position: absolute;
  top: -9px;
  right: -10px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
}

#pause .buttonIcon {
  background-color: white;
  color: rgba(255,165,0, .6);
  border: 1px solid rgba(255,165,0, .3);
}

#end .buttonIcon {
  background-color: white;
  color: rgba(255,0,0, .6);
  border: 1px solid rgba(255,0,0, .3);
}

#addTrip,
#doubleUp {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,128,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,128,0, .25);
  border-radius: 10px; 
  position: relative;
  align-self: end;
  justify-self: end;
  width: 78%;
}

#doubleUp {
  justify-self: start;
}

#addTrip button, 
#doubleUp button {
  border: 1px solid rgba(0, 128, 0, .3);
  background-color: white;
  color: black;
  padding: 7px 0;
  position: relative;
  border-radius: 10px;
}

#addTrip .buttonIcon,
#headOut .buttonIcon,
#doubleUp .buttonIcon {
  color: rgba(0, 128, 0, .6);
  background-color: white;
  border: 1px solid rgba(0, 128, 0, .3);
}

#headOut {
  position: relative;
}

#headOut .buttonIcon,
#start .buttonIcon {
  background-color: green;
  color: white;
  border: 1px solid white;
}

#tripsSelector {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

#tripsSelector h1 {
  grid-column: 1/-1;
  -ms-grid-row-align: center;
      align-self: center;
}

#tripsSelector div {
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 10px 0 rgba(0,0,0, .25);
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background-color: rgba(0,0,0, .75);
  color: white;

  display: -ms-grid;

  display: grid;
  place-items: center;
}

#tripsSelector a {
  height: 100%;
  width: 100%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  
  color: white;
  text-decoration: none;
}

.component .stepCard .inputError {
  -webkit-box-shadow: 0 0 5px 0 rgba(255,0,0, .75);
          box-shadow: 0 0 5px 0 rgba(255,0,0, .75);
}

@media (min-aspect-ratio: 1 / 1.6) {
  .component {
    grid-template-rows: 1fr clamp(33%, 45vh, 45%) !important;
  }

  .tripRoute {
    grid-template-rows: 1fr clamp(33%, 100vh, 100%) !important;
  }

  .component summary {
    grid-template-rows: 1fr 4.5fr 3.5fr;
  }
}

@media (min-aspect-ratio: 1 / 1.25) {
  .component {
    grid-template-rows: 1fr clamp(33%, 55vh, 55%) !important;
  }

  .tripRoute {
    grid-template-rows: 1fr clamp(33%, 100vh, 100%) !important;
  }

  .component summary {
    grid-template-rows: 1fr 4.5fr;
  }
}

@media (min-aspect-ratio: 1 / .8) {
  .component,
  .tripRoute {
    grid-template-rows: 1fr clamp(33%, 100vh, 100%) !important;
  }

  .component h4,
  .tripRoute h4{
    font-size: 17px;
  }

  .component summary  {
    display: none;
  }
}