:root {
  --averagedStagesColor: gold;
}

.statContainer {
  composes: statContainer from './../../shiftRouter.module.css';
  grid-column: span 6;
  grid-template-columns: 2fr 1.5fr 2fr;
}
.statContainer div {
  display: grid;
  grid-gap: 8px;
}

.statContainer b {
  align-self: start;
}

#wait {
  grid-template-rows: auto 1fr;
}

#wait .cardStat{
  grid-template-columns: none;
  grid-template-rows: auto auto;
  grid-row-gap: 0;
}

.statsHeader {
  composes: statsHeader from './../../shiftRouter.module.css';
  background-color: var(--averagedStagesColor) !important;
  color: black;
}

.cardStat {
  composes: cardStat from './../../shiftRouter.module.css';
  grid-template-columns: auto auto;
  border: 1px solid var(--averagedStagesColor);
  grid-gap: 0;
}

.cardStat .stat {
  width: 100%;
  height: 100%;
  background-color: var(--averagedStagesColor);
  font-weight: bold;
  display: grid;
  place-items: center;
  text-align: center;
  color: black;
}

@media (min-aspect-ratio: 1 / 1.6) {
  .statContainer {
    display: none;
  }
}