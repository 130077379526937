:root {
  --measurementMetricsColor: lightskyblue;
}

.statContainer {
  composes: statContainer from './../../shiftRouter.module.css';
  grid-column: span 4;
  grid-template-columns: 1fr 1fr;
}

.statsHeader {
  composes: statsHeader from './../../shiftRouter.module.css';
  background-color: var(--measurementMetricsColor) !important;
  color: black;
}

.cardStat {
  composes: cardStat from './../../shiftRouter.module.css';
  grid-template-columns: auto 1fr;
  border: 1px solid var(--measurementMetricsColor);
  text-align: center;
}

.cardStat .stat {
  display: grid;
  place-items: center;
  width: 13vw;
  height: 100%;
  background-color: var(--measurementMetricsColor);
  color: black;
  padding: 0.59vh;
  font-weight: bold;
}

.cardStat .label {
  display: grid;
  place-items: center;
  text-align: center;
}

#tripCount {
  font-size: 3.5vh;
}

.timeIndicator {
  font-size: 1.8vh;
}