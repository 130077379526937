.homePage {
  height: 100%;
  width: 100%;

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.homePage img {
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  align-self: end;

  grid-column: span 3;
}

.homePage p:nth-child(2) {
  align-self: start;
  grid-column: span 3;
}

.homePage .providers {
  grid-column: span 3;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
}

.homePage .providers h2 {
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.homePage  .providers p {
  display: grid;
  place-items: center;
  height: 75px;
}

.homePage .providers img {
  width: 12.5vw;
  height: 12.5vw;
  border-radius: 5px;
}

.homePage a {
  grid-column-start: 2;
  align-items: center;
  text-decoration: none;
  background-color: green;
  height: 50px;
  width: 100px;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0,0,0, .25);

  display: grid;
  place-items: center;
}

@media (min-aspect-ratio: 1 / 1.25) {
  .homePage {
    grid-template-rows: 1fr 1fr;
  }

  .homePage .providers {
    display: none;
  }
}

@media (min-aspect-ratio: 1 / .8) {
  .homePage {
    align-content: center;
  }
  .homePage img,
  .homePage p {
    display: none;
  }

  .homePage a {
    grid-row: 1/-1;
    align-self: center;
  }
}