.tripListContainer {
  composes: tripListContainer from './../shift/activeTrips/tripList.module.css';
  grid-template-columns: repeat(auto-fit, 100%);
  grid-column: 1/-1;
  width: 100%;
}

.tripItem {
  composes: tripItem from './../shift/activeTrips/tripList.module.css';
}

.logo {
  composes: logo from './../shift/activeTrips/tripList.module.css';
}

.dateAndInfo {
  composes: dateAndTime from './../shift/activeTrips/tripList.module.css';
}

.payInfo {
  composes: payInfo from './../shift/activeTrips/tripList.module.css';
}