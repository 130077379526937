* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

header {
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 0 0 3px rgba(0,0,128, .5);
          box-shadow: 0 0 3px rgba(0,0,128, .5);
  display: -ms-grid;
  display: grid;
  place-items: center;
}

header h1 {
  font-size: 3.8vh;
}

.container {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100vh;
  -ms-grid-rows: 5.9vh 1fr 8.9vh;
  grid-template-rows: 5.9vh 1fr 8.9vh;
}

main {
  height: 100%;
  width: 100%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

main::-webkit-scrollbar {
  display: none;
}

nav {
  z-index: 10;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 0 3px rgba(0,0,128, .5);
          box-shadow: 0 0 3px rgba(0,0,128, .5);
}

nav ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

nav ul a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
}

nav ul li {
  color: gray;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5%;
}

nav ul a li:hover {
 color: rgb(20, 39, 155);
}

.active {
  color: rgb(20, 39, 155);
}

h1.logo a {
  text-decoration: none;
  color: rgb(20, 39, 155);
}

.actionMenuWrapper {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -ms-flex-line-pack: end;
      align-content: end;
  overflow: hidden;
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 7vh;
  min-width: 45px;
  height: 7vh;
  min-height: 45px;
  border-radius: 50%;
  background-color: white;
}

.actionMenu {
  display: -ms-grid;
  display: grid;
  justify-items: end;
  -ms-flex-line-pack: end;
      align-content: end;
  grid-gap: .95vh;
  list-style: none;
  color: white;
}

.menuIcon {
  display: -ms-grid;
  display: grid;
  -ms-grid-column-align: end;
      justify-self: end;
  place-items: center;
  background-color: rgb(20, 39, 155);
  border-radius: 50%;
  width: 7vh;
  min-width: 45px;
  height: 7vh;
  min-height: 45px;
  font-size: 3.9vh;
  grid-column: 1/-1;
  cursor: pointer;
}

.actionMenu .actionIcon {
  display: -ms-grid;
  display: grid;
  place-items: center;
  background-color: rgb(20, 39, 155);
  border-radius: 50%;
  height: 5vh;
  min-height: 30px;
  width: 5vh;
  min-width: 30px;
  margin: 0 1vh 0 1vh;
}

.actionMenu .menuAction {
  display: -ms-grid;
  display: grid;
  -ms-grid-column-align: end;
      justify-self: end;
  -ms-grid-columns: 95px auto;
  grid-template-columns: 95px auto;
  height: 100%;
  cursor: pointer;
}

.actionMenu .actionLabel {
  background-color: rgb(20, 39, 155);
  padding: .6vh;
  border-radius: .6vh;
}

.closedMenu {
  grid-row: 1/-1;
}

.actionMenu li{
  display: -ms-grid;
  display: grid;
  place-items: center;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

form {
  display: -ms-grid;
  width: 80%;

  display: grid;
  grid-gap: 1.8vh;
}

form input {
  display: block;
  border-radius: 10px;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  padding: .6vh 0;
  text-align: center;
}

form input[type=text],
form input[type=number],
form input[type=submit],
form input[type=password] {
  all: unset;
  cursor: text;
  border-radius: 10px;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  padding: .6vh 0;
  text-align: center;
}

form input[type=checkbox] {
  width: auto;
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
          box-shadow: 0 0 0 0 rgba(0,0,0, 0);
}

form button,
form button:disabled{
  all: unset;
  text-decoration: none;
  background-color: green;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: .95vh 0;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
}

form button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

h4, p {
  font-size: clamp(12px, 1.89vh, 16px);
}

h3 {
  font-size: 2.21vh;
}

@media (min-aspect-ratio: 1 / 1.6) {
  .container {
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
  }

  nav {
    display: none;
  }

  header {
    display: none;
  }
}