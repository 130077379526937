.component {
  width: 95%;
  align-self: start;
  padding: .6vh 0;

  display: grid;
  grid-gap: 1.5vh;
  justify-items: center;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: 1fr 1fr;
  margin: .65vh 0 5vh 0;
}

.component h2,
.component h3 {
  align-self: start;
  position: relative;
  margin-bottom: .6vh;
  font-size: 2vh;
}

.component h2::before,
.component h3::before {
  content: '';
  height: 1px;
  width: 75%;
  background-color: black;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.card {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  -webkit-box-shadow: 0 0 6px rgba(0,0,0, .4);
          box-shadow: 0 0 6px rgba(0,0,0, .4);
  display: grid;
  grid-gap: 1.18vh;
  padding: 1.18vh;
}

.card h3 {
  justify-self: start;
}

.card div {
  display: grid;
  grid-template-columns: 16vw 1fr;
  justify-content: start;
  grid-column: 2 / -1;
}

.card .percentBar {
  grid-column: 1 / -1;
  width: 100%;
  height: .47vh;
  background-color: black;
  border-radius: 25px 0 25px 0;
  margin-top: .12vh;
}

.statsHeader {
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: .75fr 1fr;
  justify-content: center;
  align-items: center;
}

.statsHeader div {
  display: grid;
  align-items: end;
  justify-items: start;
  grid-template-columns: 1fr 1fr;
  height: 90%;
}

.statsHeader h2 {
  grid-column: 1 / -1;
  align-self: start;
}

.statsHeader h2 span {
  text-transform: capitalize;
}

.statsHeader div * {
  margin: 0 0 .6vh 0;
}

.statsHeader img {
  object-fit: cover;
  height: 11.5vh;
  width: 11.5vh;
  margin: .5vh;
  box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  border-radius: 50%;
}

.statsMenu {
  grid-column: 1 / -1;
  width: 100%;
  position: fixed;
  bottom: 8.9vh;
  height: 5vh;
  background-color: white;
  -webkit-box-shadow: 0 -3px 3px -3px rgba(0,0,128, .4);
          box-shadow: 0 -3px 3px -3px  rgba(0,0,128, .4); 
  z-index: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  place-items: center;
}

.statsMenu .menuItem {
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;

  display: grid;
  place-items: center;
}

.statsMenu .activeItem {
  -webkit-box-shadow: inset 0px 0px 3px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 3px #c1c1c1;
          box-shadow: inset 0px 0px 3px #c1c1c1;
  background-color: whitesmoke;
}

.payComparison {
  grid-column: 1 / -1;
}