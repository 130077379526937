.accountForm {
  height: 100%;
  width: 100%;

  display: grid;
  place-items: center;
}

.accountForm form {
  padding: 3.5vh;
  width: 90%;
  max-height: 95%;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  position: relative;
}

.accountForm form h3 {
  justify-self: start;
  margin-top: -1.75vh;
  margin-bottom: 1.75vh;
  position: relative;
}

.accountForm form h3::before {
  content: '';
  height: 1px;
  width: 75%;
  background-color: black;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.accountForm .providers {
  margin: 3.5vh 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accountForm .providers h4 {
  width: 100%;
  text-align: center;
}

.accountForm label h4 {
  margin-bottom: 5px;
}

.accountForm .providers label {
  margin: 5px;
  height: 12.25vw;
  width: 12.25vw;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  display: grid;
  place-items: center;
  text-align: center;
}

.accountForm .providers label img {
  height: 100%;
  width: 100%;
  position: relative;
}

.accountForm .providers input {
  cursor: pointer;
  display: none;
  opacity: 0;
}

.accountForm .providers input:checked + label{
  border: 2px solid dimgrey;
  color: black;
  -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0, .5);
          box-shadow: 0 0 2px 0 rgba(0,0,0, .5);
}

.errorBox {
  position: absolute;
  width: 95%;
  bottom: 3%;
  right: 2.5%;
  text-align: center;
  padding: .7vh 1vh;
  background-color: rgba(255,127,127,255);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
  z-index: 1000000;
}

.errorBox::after {
  content: '';
  background-color: rgba(255,127,127,255);
  height: 1.2vh;
  width: 1.2vh;
  position: absolute;
  transform: rotate(45deg);
  top: -.6vh;
  right: 50%;
  z-index: -10;
}