.tripListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 90%);
  justify-content: center;
  align-content: start;
  grid-gap: 15px;
  margin: 5px 10px;
}

.tripItem {
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  width: 100%;
  overflow: hidden;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr 1fr;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.logo {
  height: 100%;
  width: 80px;
  background-color: black;
  color: white;
  justify-self: start;

  display: grid;
  place-content: center;
  place-items: center;
}

.dateAndTime,
.payInfo {
  display: grid;
  align-items: center;
  grid-row-gap: 5px;
  margin: 5px 0;
}

.dateAndTime h3,
.payInfo h3 {
  align-self: start;
  position: relative;
}

.dateAndTime h3::before,
.payInfo h3::before {
  content: '';
  height: 1px;
  width: 75%;
  background-color: black;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.dateAndTime p,
.payInfo p {
  font-size: 15px;
}

.dateAndTime p {
  display: grid;
  grid-row-gap: 3px;
  align-items: center;
  margin-left: 1px;
}

.dateAndTime p,
.dateAndTime p span {
  position: relative;
}

/* .dateAndTime p span::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 33%;
  left: -12px;
  z-index: 10;
}

.dateAndTime p span:first-child::before {
  background-color: green;
}

.dateAndTime p::after {
  content: '';
  height: 20px;
  width: 0px;
  border-left: 2px dotted black;
  position: absolute;
  left: -9px;
} */