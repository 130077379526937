:root {
  --payRatesColor: limegreen;
}

.statContainer {
  composes: statContainer from './../../shiftRouter.module.css';
  grid-column: span 2;
}

.statContainer div {
  display: grid;
  grid-gap: .95vh;
}

.statsHeader {
  composes: statsHeader from './../../shiftRouter.module.css';
  background-color: var(--payRatesColor) !important;
}

.cardStat {
  composes: cardStat from './../../shiftRouter.module.css';
  grid-template-columns: auto 1fr;
  border: 1px solid var(--payRatesColor);
}

.cardStat .stat {
  width: 100%;
  height: 100%;
  background-color: var(--payRatesColor);
  margin-right: 2px;
  padding: 00.60vh;
  font-weight: bold;
}