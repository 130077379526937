:root {
  --totalPayColor: green;
}

.statContainer {
  composes: statContainer from './../../shiftRouter.module.css';
  grid-column: span 6;
  grid-auto-flow: column;
}

.statsHeader {
  composes: statsHeader from './../../shiftRouter.module.css';
  grid-column: 1 / -1;
  color: white;
  background-color: var(--totalPayColor) !important;
}

.cardStat {
  composes: cardStat from './../../shiftRouter.module.css';
  grid-template-columns: 1fr auto;
  border: 1px solid var(--totalPayColor);
}

.cardStat .stat {
  width: 100%;
  height: 100%;
  background-color: var(--totalPayColor);
  color: white;
  padding: 00.60vh;
  font-weight: bold;
}