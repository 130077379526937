.tripViewContainer {
  justify-self: center;
  width: 95%;

  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  align-items: start;
  grid-row-gap: 10px;
  position: relative;
  margin-top: 5px;
}

.tripViewHeader {
  grid-column: 1 / -1;
  width: 100%;
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 8px 0 rgba(0,0,0, .25);
  border-radius: 5px;
  overflow: hidden;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr 1fr;
  place-items: center;
}

.tripViewHeader .logoContainer {
  height: 100%;
  width: 80px;
  background-color: black;
  color: white;
  justify-self: start;
  align-self: start;
  border-radius: 5px 0 0 0;

  display: grid;
  place-items: center;
}

.capitalize {
  text-transform: capitalize;
}

.tripInfo,
.tripPayInfo {
  display: grid;
  align-items: center;
  grid-row-gap: 10px;
  margin: 5px 0;
}

.tripInfo h3,
.tripPayInfo h3,
.timeLineContainer h3 {
  align-self: start;
  position: relative;
}

.tripInfo h3::before,
.tripPayInfo h3::before,
.timeLineContainer h3::before {
  content: '';
  height: 1px;
  width: 75%;
  background-color: black;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.statBarsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.tripInfo p,
.tripPayInfo p {
  font-size: 15px;
}

.tripInfo p {
  display: grid;
  grid-row-gap: 3px;
  align-items: center;
}

.timeLineContainer {
  grid-template-rows: auto 1fr;
  display: grid;
  height: 80vh;
  width: 100%;
  grid-row-gap: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 8px 0 rgba(0,0,0, .25);
  padding: 8px 13px;
  margin-bottom: 2.37vh;
}

.timeLineContainer h3 {
  place-self: start;
  margin-top: 5px;
}

.timeLineHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.timeLineHeading p {
  font-size: 14px;
  justify-self: center;
}

.timeLine {
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;

  display: grid;
  grid-gap: 5px;
}

.timeLine > * {
  margin-left: 16px;
}

.timeLine .pickup,
.timeLine .wait,
.timeLine .delivery {
  align-self: center;
  justify-self: end;
  position: relative;
  height: 90%;
  width: 90%;

  display: grid;
  place-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0, .25);
          box-shadow: 0 0 5px rgba(0,0,0, .25);
}

.timeLine .wait {
  grid-template-rows: 1fr;
}

.timeLine .wait {
  align-content: center;
}

.timeLine .endTime,
.timeLine .pickupArrival {
  align-self: end;
}

.timeLine .startTime,
.timeLine .endTime,
.timeLine .pickupArrival,
.timeLine .pickupDeparture {
  position: relative;
}

.timeLine .startTime::before,
.timeLine .endTime::before,
.timeLine .pickupArrival::before,
.timeLine .pickupDeparture::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: -15px;
  z-index: 0;
}

.timeLine .pickupArrival::before,
.timeLine .pickupDeparture::before {
  background-color: black;
}

.timeLine .endTime::before {
  background-color: red;
  top: auto;
  bottom: 5px;
}

.timeLine::after {
  content: '';
  height: 93%;
  width: 0px;
  border-left: 2px dotted black;
  position: absolute;
  left: 14px;
  top: 3.25%;
  z-index: -1;
}

.editButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: black;
  color: white;
  cursor: pointer;


  display: grid;
  place-items: center;
  font-size: 20px;
  z-index: 3;
  -webkit-box-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
          box-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
}

.editForm {
  position: absolute;
  bottom: 35%;
  box-shadow: 0 0 0 1000vw rgba(0, 0, 0, 0.85);
  z-index: 2;
  border: 5px;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 30%;

  display: grid;
  place-items: center;
}

.editForm form {
  display: grid;
  place-items: center;
  grid-gap: 10px;
}

.editForm form label {
  position: relative;
  width: 100%;
}

.editForm form input {
  all: unset;
  display: block;
  border-radius: 10px;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 5px 0 rgba(0,0,0, .25);
  padding: 5px 0;
  text-align: center;
}

.editForm form button,
.editForm form button:disabled{
  all: unset;
  text-decoration: none;
  background-color: green;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
}