:root {
  --averagedPayColor: darkgreen;
  --averagedtotalColor: lightblue;
}

.statContainer {
  composes: statContainer from './../../shiftRouter.module.css';
  grid-column: span 6;
  grid-template-columns: 5fr 3fr;
  grid-gap: 1vh;
}

.statContainer > div{
  display: grid;
  grid-gap: .95vh;
}

#averagedPay {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#total {
  grid-column: 1 / -1;
}

.statsHeader {
  composes: statsHeader from './../../shiftRouter.module.css';
  color: white;
}

.cardStat {
  composes: cardStat from './../../shiftRouter.module.css';
  grid-template-columns: auto 1fr;
  grid-gap: .35vh;
  border: 1px solid var(--averagedPayColor);
}

.cardStat .stat {
  width: 100%;
  height: 100%;
  background-color: var(--averagedPayColor);
  color: white;
  padding: 00.60vh;
  font-weight: bold;
  display: grid;
  place-items: center;
  text-align: center;
}

#averagedMetrics .statsHeader,
#averagedMetrics .cardStat .stat {
  background-color: var(--averagedtotalColor);
  color: black;
}

#averagedPay .statsHeader {
  background-color:  var(--averagedPayColor);
}

#averagedMetrics .cardStat {
  border: 1px solid var(--averagedtotalColor);
}

@media (min-aspect-ratio: 1 / 1.25) {
  .statContainer {
    display: none;
  }
}