.tripViewContainer {
  composes: tripViewContainer from './../shift/activeTrips/tripView.module.css';
  grid-column: 1 / -1;
  grid-template-rows: 1fr;
  width: 100%;
  box-shadow: none;
}

.tripViewHeader {
  composes: tripViewHeader from './../shift/activeTrips/tripView.module.css';
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0,0,0, .30)
}

.logoContainer {
  composes: logoContainer from './../shift/activeTrips/tripView.module.css';
  place-content: center;
}

.tripInfo {
  composes: tripInfo from './../shift/activeTrips/tripView.module.css';
}

.tripPayInfo {
  composes: tripPayInfo from './../shift/activeTrips/tripView.module.css';
}

.listCard  {
  grid-column: 1 / -1;
  width: 100%;
  box-shadow: 0 0 8px rgba(0,0,0, .30);
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  color: black;

  display: grid;
  place-items: center;
  place-content: center;
}