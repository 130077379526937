.errorBox {
  position: absolute;
  width: 100%;
  top: -125%;
  text-align: center;
  padding: .7vh 1vh;
  background-color: rgba(255,127,127,255);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
          box-shadow: 0 0 2.5px 0 rgba(0,0,0, .25);
  z-index: 1000000;
}

.errorBox::after {
  content: '';
  background-color: rgba(255,127,127,255);
  height: 1.2vh;
  width: 1.2vh;
  position: absolute;
  transform: rotate(45deg);
  bottom: -.6vh;
  right: 50%;
  z-index: -10;
}